import { Col } from "react-bootstrap";

export const DeployedCard = ({ title, description, imgUrl, link,github }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} />
        <div className="proj-txtx">
          <h4>{title}</h4>
          <span>{description}</span> <br/>
          <h4 ><a href={link} className="source_code"> Demo Website</a></h4>
        </div>
      </div>
    </Col>
  )
}
