import { Col } from "react-bootstrap";

export const ProblemCard = ({ title, description, imgUrl, solution_link }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} />
        <div className="proj-txtx">
          <h4>{title}</h4>
          <span>{description}</span> <br/>
          <a href={solution_link} className="source_code"> Best Animated Solution</a>
        </div>
      </div>
    </Col>
  )
}
