import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import { DeployedCard } from "./DeployedCard";
import projImg1 from "../assets/img/real_state2.jpg";
import jobapplication from "../assets/img/Job-Application.webp";
import projImg2 from "../assets/img/food1.jpg";
import problem_solving from "../assets/img/problem_solving.webp";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { ProblemCard } from "./ProblemCard";

export const Projects = () => {

  const projects = [
    {
      title: "Real State Management",
      description: "Frontend: React Backend: Springboot database: Postgresql",
      imgUrl: projImg1,
      github: "https://github.com/danielhundessa/real-state-management"
    },
    {
      title: "Food Order App",
      description: "Frontend: React Backend: Springboot database: mysql",
      imgUrl: projImg2,
      github: "https://github.com/danielhundessa/food-ordering-application"
    }
   
  ];
  const deployed = [
    {
      title: "Job Application Manager",
      description: "Frontend: React Backend: Springboot database: mysql",
      imgUrl: jobapplication,
      link: "http://danielsoftwareworks-frontends3.s3-website-us-east-1.amazonaws.com/"
    }
   
  ];

  const problems = [
    {
      title: "Leetcode 189. Rotate Array",
      description: "medium",
      imgUrl: problem_solving,
      solution_link: "https://youtu.be/Ib2Org0cwak"
    }
   
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p>I am currently working on projects involving web development, improving software performance, and implementing new features based on user feedback. Additionally, I am focusing on integrating cloud services to enhance scalability and reliability. I am also involved in debugging and maintaining existing codebases to ensure optimal functionality and security. Moreover, I am collaborating with cross-functional teams to deliver robust software solutions and continuously learning new technologies to stay up-to-date with industry trends.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">React + Springboot</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Problem Solving</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Deployed</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>

                    <Tab.Pane eventKey="second">
                      <Row>
                        {
                          problems.map((problem, index) => {
                            return (
                              <ProblemCard
                                key={index}
                                {...problem}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                   
                    <Tab.Pane eventKey="third">
                    <Row>
                        {
                          deployed.map((deploy, index) => {
                            return (
                              <DeployedCard
                                key={index}
                                {...deploy}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
